// @mui 
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { CardProps } from '@mui/material/Card';
import Typography from '@mui/material/Typography';
// assets
import { PlanFreeIcon, PlanStarterIcon, PlanPremiumIcon } from 'src/assets/icons';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

type Props = CardProps & {
  card: {
    subscription: string;
    price: number;
    caption: string;
    labelAction: string;
    lists: string[];
    link: string;
    yearlyPrice: number;
    yearlyLink: string;
    featured?: boolean;
    yearlyNonDiscountedPrice?: number;
  };
  index: number;
  monthly: boolean;
};

export default function PricingCard({ card, sx, monthly, ...other }: Props) {
  const { subscription, price, caption, lists, labelAction, link, yearlyPrice, yearlyLink, featured, yearlyNonDiscountedPrice } = card;

  const basic = subscription === 'basic';

  const pro = subscription === 'professional';

  const premium = subscription === 'elite';

  const renderIcon = (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Box sx={{ width: 48, height: 48 }}>
        {basic && <PlanFreeIcon />}
        {pro && <PlanStarterIcon />}
        {premium && <PlanPremiumIcon />}
      </Box>

      {pro && <Label color="info">POPULAR</Label>}
    </Stack>
  );

  const renderSubscription = (
    <Stack spacing={1}>
      <Typography variant="h4" sx={{ textTransform: 'capitalize' }}>
        {subscription}
      </Typography>
      {featured ? <Label color="info">POPULAR</Label> : null}

      <Typography variant="subtitle2">{caption}</Typography>
    </Stack>
  );

  const renderPrice = (
    <Stack direction="row">
      <Stack direction="column" gap={"1px"}>
      {!monthly ? 
      <Stack direction="row" ><Typography sx={{textDecoration: "line-through", color:"text.disabled"}} variant="h6">$
      </Typography><Typography sx={{textDecoration: "line-through", color:"text.disabled"}} variant="h3">{yearlyNonDiscountedPrice}</Typography> 
      <Typography
        component="span"
        sx={{ alignSelf: 'center', color: 'text.disabled', ml: 1, typography: 'body2' }}
      >
        / {monthly ? 'mo' : 'yr'}
      </Typography>
       </Stack> : ""}
        <br/>
       <Stack direction="row">
      <Typography variant="h4">$</Typography>
      <Typography variant="h2">{monthly ? price : yearlyPrice}</Typography>
      <Typography
        component="span"
        sx={{ alignSelf: 'center', color: 'text.disabled', ml: 1, typography: 'body2' }}
      >
        / {monthly ? 'mo' : 'yr'}
      </Typography>
      {!monthly ? <Label sx={{ml: "10px"}} color="info">20% OFF</Label> : ""}
      </Stack>
      
      </Stack>
      
    </Stack>
  );

  const renderList = (
    <Stack spacing={2}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Box component="span" sx={{ typography: 'overline' }}>
          Features
        </Box>
      </Stack>

      {lists.map((item) => (
        <Stack
          key={item}
          spacing={1}
          direction="row"
          alignItems="center"
          sx={{
            typography: 'body2',
          }}
        >
          <Box sx={{ width: 16, height: 16, display: 'flex', justifyContent: 'center', alignItems: 'center', mr: 1 }}>
            <Iconify icon="eva:checkmark-fill" width={16} />
          </Box>
          <Box sx={{ flex: 1 }}>
            {item}
          </Box>
        </Stack>
      ))}
    </Stack>
  );

  return (
    
    <Stack
      spacing={5}
      sx={{
        border: featured ? 'solid 1px' : 'none',
        padding: featured ? 0 : "50px",
        p: 5,
        borderRadius: 2,
        boxShadow: (theme) => ({
          xs: theme.customShadows.card,
          md: 'none',
        }),
        ...(pro && {
          borderTopRightRadius: { md: 0 },
          borderBottomRightRadius: { md: 0 },
        }),
        ...((pro || premium) && {
          boxShadow: (theme) => ({
            xs: theme.customShadows.card,
            md: `-40px 40px 80px 0px ${alpha(
              theme.palette.mode === 'light' ? theme.palette.grey[500] : theme.palette.common.black,
              0.16
            )}`,
          }),
        }),
        ...sx,
      }}
      {...other}
    >
   
     
      {renderSubscription}

      {renderPrice}

      <Divider sx={{ borderStyle: 'dashed' }} />

      {renderList}

      <Button
        fullWidth
        size="large"
        variant="contained"
        color={pro ? 'primary' : 'inherit'}
        href={monthly ? link : yearlyLink}
        target="_blank"
      >
        {labelAction}
      </Button>
    </Stack>
  );
}
